.textarea {
    font: 14px sans-serif;

    width: 100%;
    box-sizing: border-box;
    overflow: auto; /* Note: to prevent a permanent scroll in the IE */

    border: 1px solid #CCCCCC;
    border-radius: 4px;

    padding: 6px 12px;

    background-color: #FFFFFF;

    &:active,
    &:focus {
        outline: none;
    }
}

.textarea_error {
    border-color: #FF0000;
}

.textarea_disabled {
}

.textarea_withCounter {
    display: block;
}

.textareaWrapper {
    position: relative;
}

.textareaWrapper__counter {
    font: 12px sans-serif;
    position: absolute;
    right: 12px;
    bottom: 1px;
    background-color: rgba(255, 255, 255, 0.7);
    color: #777777;
    padding: 5px;
}

.textareaWrapper__counter_warning {
    color: #AD2424;
}
