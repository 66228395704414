.alert {
  box-sizing: border-box;
  display: block;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  & + .alert {
      margin-top: 15px;
    }
}

.alert_success {
  color: #3C763D;
  background-color: #DFF0D8;
  border-color: #D6E9C6;
}

.alert_info {
  color: #31708F;
  background-color: #D9EDF7;
  border-color: #BCE8F1;
}

.alert_warning {
  color: #8a6d3b;
  background-color: #FCF8E3;
  border-color: #fAEBCC;
}

.alert_error {
  color: #A94442;
  background-color: #F2DEDE;
  border-color: #EBCCD1;
}
.alert__icon {

}
.alert__icon_error {

}
.alert__icon_success {

}
.alert__icon_info {

}
.alert__icon_warning {

}
.alert__text {

}