.radioButton {
  & + & {
    margin-left: 30px;
  }

  position: relative;
  display: inline-block;
  padding: 5px 0 5px 36px;

  label {
    display: inline-block;
    font-size: 14px sans-serif;
    text-align: left;
    cursor: pointer;
  }

  i {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #D5D5D5;
    background-color: #FFFFFF;
    &::before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      background-color: #2E86BB;
      border-radius: 50%;
      margin: 7px;
      transform: scale(0);
      transition: transform .1s;
    }
  }

  input[type=radio] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    left: 0;
    top: 0;
    &:checked + i::before {
      transform: scale(1);
    }
  }
}

.radioButton_disabled {

}

.radioButton__text {

}
