/* We have set of classes */

.checkbox {
  input {
    vertical-align: middle;
  }
}

.checkbox_error {

}
.checkbox_disabled {

}
.checkbox__customStyle {
  display: none;
}


/* We can make override styles, for example:  */


/*
.checkbox {
  position: relative;

  .checkbox__customStyle {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    background: #DEE1E4;
    transition: background 0.3s ease;
    cursor: pointer;
  }

  input[type=checkbox] {
    visibility: hidden;
    width: 0;
    height: 0;

    &:checked + .checkbox__customStyle {
      border: 5px solid #DEE1E4;
      background: #575CE4;
      transition: background 0.3s ease;
    }
  }
}
*/
