.scheduleControl {

}

.topHeaders {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
}

.timeText {

}

.dayHeader {
    justify-content: center;
}

.hourControls {
    display: flex;
    justify-content: space-between;

    & + & {
       margin-top: 5px;
    }
}

.hourHeader {
    padding-left: 10px;
}

.hourControl {
    position: relative; /* Needed for hourControl__hoverIcon position absolute */
    border: 1px solid;
}

.hourControl_workday {

}

.hourControl_holiday {

}

.hourControl_inactive {
    &:hover {
        .hourControl__hoverIcon {
            display: block;
        }
    }
}

.hourControl_active {
    border: none;
}

.hourControl__hoverIcon {
    display: none;
    pointer-events: none; /* Needed to allow a click on the underlying .hourControl */

    position: absolute;
    top: 0;
    left: 0;
}

.dayHeader,
.hourControl {
    & + & {
        margin-left: 2px;
    }
}

.dayHeader,
.timeText,
.hourHeader {
    display: flex;
    align-items: center;
}

.hourControl,
.timeText,
.dayHeader,
.hourHeader {
    box-sizing: border-box;
    height: 24px;
}

.hourHeader,
.timeText {
    width: 28px;
}

.dayHeader,
.hourControl {
    width: 24px;
}

.pointer {
    cursor: pointer;
}
