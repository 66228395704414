.link {
    font: 14px sans-serif;

    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    margin: 0;
    outline: none;

    &:focus,
    &:hover {
        outline: none;
    }
}

.link_active {

}

.link__icon + .link__text {
    margin-left: 8px;
}

.link__text + .link__icon {
    margin-left: 8px;
}
