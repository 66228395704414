@value hintColor: #FBC302;
@value transitionDuration: 200ms;

.container {
    position: relative;
}

.container__input {
    font: 14px sans-serif;

    width: 100%;
    height: 34px;
    box-sizing: border-box;

    border: 1px solid #CCCCCC;
    border-radius: 4px;

    padding: 6px 12px;

    background-color: #FFFFFF;

    &:active,
    &:focus {
        outline: none;
    }
}

.container__input_error {
    border-color: #FF0000;
}

.container__input_disabled {

}

.container__strengthMeter {
    position: absolute;
    right: 15px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.container__hint {
    font: 12px sans-serif;

    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
    margin-bottom: 7px;
    padding: 4px 9px;
    border-radius: 5px;
    border: 1px solid #FBC302;
    background: #FBC302;
    box-shadow: 2px 3px 6px #CCCCCC;
    box-sizing: border-box;
    color: #FFFFFF;

    visibility: hidden;
    transform: translateY(10px);
    opacity: 0;
    transition: visibility step-end, opacity ease, transform step-end;
    transition-duration: transitionDuration;

    &::before {
        position: absolute;
        top: 100%;
        right: 25px;
        content: " ";
        pointer-events: none;
        border: solid rgba(0, 0, 0, 0);
        border-top-color: #FBC302;
        border-width: 8px;
    }
}

.container__hint_opened {
    transform: translateY(0);
    visibility: visible;
    transition: visibility step-start, opacity ease, transform ease;
    transition-duration: transitionDuration;
    opacity: 1;
}
