.actionLink {
    font: 14px sans-serif;

    display: inline-block;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    outline: none;

    &:focus,
    &:hover {
        outline: none;
    }

    &::-moz-focus-inner {
        padding: 0;
        border: 0
    }
}

.actionLink_disabled {
    cursor: default;
}

.actionLink__inner {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.actionLink__icon + .actionLink__text,
.actionLink__text + .actionLink__icon {
    margin-left: 8px;
}
