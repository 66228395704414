:global {
    .rw-date-picker {
        &.rw-state-focus {
            .rw-widget-picker {
                box-shadow: none;
            }
        }

        &.rw-state-disabled {
            cursor: default;
        }

        .rw-widget-picker {
            min-height: 34px;

            .rw-input {
                box-shadow: none;
            }
        }

        .rw-popup {
            box-shadow: none;
        }

        .rw-calendar {
            .rw-calendar-header {
                .rw-calendar-btn {
                    border: 1px solid transparent;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }

            .rw-calendar-grid {
                .rw-cell {
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }

        .rw-widget-input {
            box-shadow: none;
        }
    }
}
