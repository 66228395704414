.inputFile {
}

.inputFile__input {
    display: none;
}

.inputFile__button {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.inputFile__addon {
    border: none;

    &:first-child {
        padding: 0;
    }
}
