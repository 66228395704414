.container {
    display: inline-table;
    border-spacing: 0;
    width: 100%;
}

.container__input {
    font: 14px sans-serif;

    display: table-cell;
    width: 100%;
    height: 34px;
    box-sizing: border-box;

    border: 1px solid #CCCCCC;
    border-radius: 4px;

    padding: 6px 12px;

    background-color: #FFFFFF;

    &:active,
    &:focus {
        outline: none;
    }

    &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
    }

    &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }
}

.container__input_error {
    border-color: #FF0000;
}

.container__addon {
    font: 14px sans-serif;

    display: table-cell;
    min-width: 34px;
    width: 34px;
    height: 34px;
    box-sizing: border-box;

    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    color: #555555;
    background: #EEEEEE;

    border: 1px solid #CCC;

    &:first-child {
        padding: 0 5px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &:last-child {
        padding: 0 5px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.container__addon_error {
    border-color: #FF0000;
}

.container__input_active {

}

.container__addon_active {

}

.container__input_disabled {

}

.container__addon_disabled {

}
