.meter {
    width: 67px;
    height: 22px;
}

.meter__bar {
    display: inline-block;
    height: 22px;
    width: 4px;
    border-radius: 2px;
    background-color: #E4E4E4;
    font-size: 0;

    & + & {
        margin-left: 3px;
    }
}

.meter__bar_low {
    background-color: #FF0000;
}

.meter__bar_medium  {
    background-color: #FBC300;
}

.meter__bar_high {
    background-color: #3BB300;
}
