@value button_bgColor: #FD843F;
@value button_bgColor_active: #E86B23;
@value button_bgColor_disabled: #B9B9B9;
@value button_bgColor_hover: #FF6913;
@value button_bgColor_focus: #FF9558;

@value color: #FFFFFF;

.button {
    font: 16px sans-serif;
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 36px;
    border-radius: 6px;
    color: color;
    border: none;
    background-color: button_bgColor;
    cursor: pointer;
    transition: background-color .15s ease;

    &:active,
    &:focus,
    &:hover {
        outline: none;
    }

    &:active {
        background-color: button_bgColor_active;
    }
    &:focus {
        background-color: button_bgColor_focus;
    }
    &:hover {
        background-color: button_bgColor_hover;
    }
}

.button_disabled {
    cursor: default;
    background-color: button_bgColor_disabled;
    transition: .3s ease .5s;

    &:active,
    &:focus,
    &:hover {
        background-color: button_bgColor_disabled;
    }
}
.button_spinner {
    cursor: default;

    &:active,
    &:focus,
    &:hover {
        background-color: button_bgColor;
    }
}

.button__inner {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.button__icon + .button__text,
.button__text + .button__icon {
    margin-left: 8px;
}

.button__spinner {

}
