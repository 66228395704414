 .mobileNumber {
    display: flex;
}

.mobileNumber__input {
    flex: 1 1 auto;
    width: 0;

    &:first-child {
        max-width: 100px;
        text-align: right;
    }

    & + & {
        margin-left: 15px;
    }
}
