.spinner {
    width: 44px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.spinner__bounce {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 100%;
    animation: sk-bouncedelay 1.2s infinite ease-in-out both;
}

.spinner__bounce_1 {
    animation-delay: .25s;
}

.spinner__bounce_2 {
    animation-delay: .50s;
}

.spinner__bounce_3 {
    animation-delay: .75s;
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        transform: scale(0.5);
    } 40% {
        transform: scale(1.0);
    }
}
