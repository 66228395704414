.heading {
    margin: 0;
    padding: 0;
}

.heading_level_1 {

}

.heading_level_2 {

}

.heading_level_3 {

}

.heading_level_4 {

}

.heading_level_5 {

}

.heading_level_6 {

}

.heading_level_7 {

}
