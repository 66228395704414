.dayMonthYear {
    display: flex;
}

.dayMonthYear__day + .dayMonthYear__month,
.dayMonthYear__month + .dayMonthYear__year {
    margin-left: 15px;
}

.dayMonthYear__day,
.dayMonthYear__month,
.dayMonthYear__year {
    flex: 1 0 0;
}
