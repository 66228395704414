.alertsBox {

}

/* For React CSS Transition */
.alertEnter {
    opacity: 0.01;
    transition: opacity .5s ease-in;
}

.alertEnter.alertEnterActive {
    opacity: 1;
    transition: opacity .5s ease-in;
}

.alertExit {
    opacity: 1;
    transition: opacity .5s ease-in;
}

.alertExit.alertExitActive {
    opacity: 0.01;
    transition: opacity .5s ease-in;
}
