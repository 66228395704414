@value marginTop: 8px;

.ibanTemplate {
    margin-top: calc(-1 * marginTop);
}

.ibanTemplate__input {
    margin-top: marginTop;
    margin-right: 10px;
}

.simpleInput {
    width: 100%;
    margin-right: 10px;
}

.changeModeLink {
}
