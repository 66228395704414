.input {
    font: 14px sans-serif;

    width: 100%;
    height: 34px;
    box-sizing: border-box;

    border: 1px solid #CCCCCC;
    border-radius: 4px;

    padding: 0px 12px;

    background-color: #FFFFFF;

    &.input_showAsFocused,
    &:active,
    &:focus {
        outline: none;
    }
}

.input_error {
    border-color: #FF0000;
}

.input_disabled {
    transition: .3s ease .5s;
}
