.field {
    display: flex;
    width: 100%;

    & + & {
        margin-top: 5px;
    }
}
.field_required {

}
.field_column {
    flex-direction: column;
}

.field_error {
    color: #FF0000;
}
.field_disabled {

}

.label {
    height: 30px;
    box-sizing: border-box;

    display: flex;
    white-space: nowrap;
    align-items: center;
    text-align: right;

    margin-left: auto;
    padding-right: 10px;

    &:empty {
        display: none;
    }
}
.label_required {

}

.label_column {
    text-align: left;
    margin-left: 0;
}
.label__labelElement {

}
.label__labelElement_forElement {
    cursor: pointer;
}
.label__labelElement_disabled {
    cursor: default;
}

.controls_labeled,
.controls {
    width: 210px;
    flex: 0 0 auto;
}

.control {

}

.helpBlock {
    font: 14px sans-serif;
    width: 100%;
    color: gray;
    margin-top: 5px;
}

.errorsBlock {
    width: 100%;
    margin-top: 5px;
}

.error {
    margin: 0;
    text-align: left;

    & + & {
        margin-top: 5px;
    }
}
