.dateRangeField {
    width: 430px;
    display: flex;
}

.dateRangeField__cell {
    flex: 1 0 0;
    min-width: 0; /* Note: to make it shrink below width: auto */
    height: 34px; /* Note: needed for the IE to prevent a bug: IE makes its height = 0px when using flex basis 0 */

    & + & {
        margin-left: 20px;
    }
}
