@value contentMinWidth: 320px;
@value modalPadding: 20px;

.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    overflow: auto;
}

.modal_fillBackground {
    background-color: rgba(60, 60, 60, 0.66);
}

.modal_clearBackground {
    background: none;
}

.bodyWrapper{
}

.bodyWrapper_ieCenterHelper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.bodyWrapper_verticalHelper {
    display: flex;
    flex-direction: column;

    flex: 1 0 auto;
}

/*
    Centering:
    justify-content, align-items, margin: auto doesn't always work in the IE,
    that's why margin: 0 auto; and margin: auto 0; are used.
*/

.bodyWrapper_horizontalHelper {
    display: flex;

    flex: 0 0 auto;
    margin: auto 0; /* parent-flex + margin: auto = centering */
}

.body {
    margin: 0 auto; /* parent-flex + margin: auto = centering */

    min-width: contentMinWidth;
    flex: 0 0 auto;

    background-color: #FFFFFF;

    border: 1px solid #CCCCCC;
    border-radius: 6px;

    position: relative;
}

.modal_withForm {
    .body {
        width: formBreakPoint;

        @media screen and (width <= $formBreakPoint) {
            width: 100%;
        }
    }
}

.body_withCloseIcon {

}

.header {
    position: relative;
    height: 60px;
    margin: 0;
    padding: modalPadding;
    box-sizing: border-box;
    background-color: #F0F0F0;
}

.header_withCloseIcon {
}

.headerContent {
    font: 18px sans-serif;

    position: absolute; /* Modal width does not depend on header width */
    width: 100%;
    padding-right: 60px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.headerContent_withCloseIcon {
}

.controls {
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    align-items: center;
}

.control {
    & + & {
        margin-left: 15px;
    }
}

.closeButton {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0NkIzRTc3MkY4NjRFNjExQTJCN0E5OUY4MzE3OEUxRCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpGOUQ2QTAzODc2M0MxMUU2QkE1OEVGQjE5NEFGODIwRSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpGOUQ2QTAzNzc2M0MxMUU2QkE1OEVGQjE5NEFGODIwRSIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IFdpbmRvd3MiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyMzg3QjQ4RTI3NjhFNjExOUU4ODkyRkUyMzlBODE2QyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkIzRTc3MkY4NjRFNjExQTJCN0E5OUY4MzE3OEUxRCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PoIhXKEAAABgSURBVHjaYmBgYHjBQBl4gcEg1YD///9jN5EUF6AbQopBcHXYDCHGIBR5XIbgMwhDHJ8h2DRgNZiQIcgacXqRGENA4C8+Sbq4hOIwoTh2KE4nFKdYivMOxbmY4vIEIMAAwtZK0SI573cAAAAASUVORK5CYII=');
    width: 17px;
    height: 17px;
    opacity: .5;
    transition: opacity .3s;

    &:hover {
        cursor: pointer;
        opacity: 1;
    }
}

.content {
    padding: modalPadding;
}

.content_noHeader {
}

.footer {
    border-top: 1px solid #E5E5E5;
    padding: modalPadding;
}
