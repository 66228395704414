.link__vdek- {
    font: 14px sans-serif;

    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    margin: 0;
    outline: none;
}

    .link__vdek-:focus,
    .link__vdek-:hover {
        outline: none;
    }

.link_active__qa8O- {

}

.link__icon__GGg-y + .link__text__UZLne {
    margin-left: 8px;
}

.link__text__UZLne + .link__icon__GGg-y {
    margin-left: 8px;
}



.actionLink__ijNny {
    font: 14px sans-serif;

    display: inline-block;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    outline: none;
}

    .actionLink__ijNny:focus,
    .actionLink__ijNny:hover {
        outline: none;
    }

    .actionLink__ijNny::-moz-focus-inner {
        padding: 0;
        border: 0
    }

.actionLink_disabled__O-P3y {
    cursor: default;
}

.actionLink__inner__iufg6 {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.actionLink__icon__yyHTN + .actionLink__text__mXJEt,
.actionLink__text__mXJEt + .actionLink__icon__yyHTN {
    margin-left: 8px;
}



.spinner__sD6wc {
    width: 44px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.spinner__bounce__-pJdc {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 100%;
    animation: sk-bouncedelay__phxyA 1.2s infinite ease-in-out both;
}

.spinner__bounce_1__dts1w {
    animation-delay: .25s;
}

.spinner__bounce_2__UwusZ {
    animation-delay: .50s;
}

.spinner__bounce_3__pIE42 {
    animation-delay: .75s;
}

@keyframes sk-bouncedelay__phxyA {
    0%, 80%, 100% {
        transform: scale(0.5);
    } 40% {
        transform: scale(1.0);
    }
}



.icon__M3AOI {
    display: inline-block;
    flex: 0 0 auto;
}



.alertsBox__-Rymn {

}

/* For React CSS Transition */
.alertEnter__Enlug {
    opacity: 0.01;
    transition: opacity .5s ease-in;
}

.alertEnter__Enlug.alertEnterActive__1BSQU {
    opacity: 1;
    transition: opacity .5s ease-in;
}

.alertExit__80TZf {
    opacity: 1;
    transition: opacity .5s ease-in;
}

.alertExit__80TZf.alertExitActive__Wr7fi {
    opacity: 0.01;
    transition: opacity .5s ease-in;
}

.alert__y-Vxg {
  box-sizing: border-box;
  display: block;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
}
  .alert__y-Vxg + .alert__y-Vxg {
      margin-top: 15px;
    }

.alert_success__epAKg {
  color: #3C763D;
  background-color: #DFF0D8;
  border-color: #D6E9C6;
}

.alert_info__p5dKh {
  color: #31708F;
  background-color: #D9EDF7;
  border-color: #BCE8F1;
}

.alert_warning__IwOvb {
  color: #8a6d3b;
  background-color: #FCF8E3;
  border-color: #fAEBCC;
}

.alert_error__SSFTU {
  color: #A94442;
  background-color: #F2DEDE;
  border-color: #EBCCD1;
}
.alert__icon__2aG-7 {

}
.alert__icon_error__Y1Ev5 {

}
.alert__icon_success__SoZcy {

}
.alert__icon_info__CSfrt {

}
.alert__icon_warning__wQaF4 {

}
.alert__text__0fHR7 {

}


.button__nKOVm {
    font: 16px sans-serif;
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 36px;
    border-radius: 6px;
    color: #FFFFFF;
    border: none;
    background-color: #FD843F;
    cursor: pointer;
    transition: background-color .15s ease;
}

.button__nKOVm:active,
    .button__nKOVm:focus,
    .button__nKOVm:hover {
        outline: none;
    }

.button__nKOVm:active {
        background-color: #E86B23;
    }

.button__nKOVm:focus {
        background-color: #FF9558;
    }

.button__nKOVm:hover {
        background-color: #FF6913;
    }

.button_disabled__-a4jt {
    cursor: default;
    background-color: #B9B9B9;
    transition: .3s ease .5s;
}

.button_disabled__-a4jt:active,
    .button_disabled__-a4jt:focus,
    .button_disabled__-a4jt:hover {
        background-color: #B9B9B9;
    }
.button_spinner__t1xlh {
    cursor: default;
}
.button_spinner__t1xlh:active,
    .button_spinner__t1xlh:focus,
    .button_spinner__t1xlh:hover {
        background-color: #FD843F;
    }

.button__inner__dfMG9 {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.button__icon__lkTkW + .button__text__acx7Q,
.button__text__acx7Q + .button__icon__lkTkW {
    margin-left: 8px;
}

.button__spinner__rrwr9 {

}



.buttonsContainer__AMYFu {
    display: flex;
    justify-content: flex-end;
}

.buttonsContainer__button__w3k7C {
    min-width: 170px;
    width: 170px;
    height: 32px;
}

.buttonsContainer__button__w3k7C + .buttonsContainer__button__w3k7C {
        margin-left: 15px;
    }



.html_withModal__sx25W {
    overflow: hidden;
}

.modalsStack__ICrmQ {

}

.modal__xgAg3 {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    overflow: auto;
}

.modal_fillBackground__psu-i {
    background-color: rgba(60, 60, 60, 0.66);
}

.modal_clearBackground__15Tsy {
    background: none;
}

.bodyWrapper__Dqg8A{
}

.bodyWrapper_ieCenterHelper__gljM5 {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.bodyWrapper_verticalHelper__GvauB {
    display: flex;
    flex-direction: column;

    flex: 1 0 auto;
}

/*
    Centering:
    justify-content, align-items, margin: auto doesn't always work in the IE,
    that's why margin: 0 auto; and margin: auto 0; are used.
*/

.bodyWrapper_horizontalHelper__REuIf {
    display: flex;

    flex: 0 0 auto;
    margin: auto 0; /* parent-flex + margin: auto = centering */
}

.body__9J2YT {
    margin: 0 auto; /* parent-flex + margin: auto = centering */

    min-width: 320px;
    flex: 0 0 auto;

    background-color: #FFFFFF;

    border: 1px solid #CCCCCC;
    border-radius: 6px;

    position: relative;
}

.modal_withForm__3XOYu .body__9J2YT {
        width: formBreakPoint;
    }

@media screen and (max-width: 600px) {

.modal_withForm__3XOYu .body__9J2YT {
            width: 100%
    }
        }

.body_withCloseIcon__MvE3B {

}

.header__uXSbl {
    position: relative;
    height: 60px;
    margin: 0;
    padding: 20px;
    box-sizing: border-box;
    background-color: #F0F0F0;
}

.header_withCloseIcon__Coi6k {
}

.headerContent__D-wZJ {
    font: 18px sans-serif;

    position: absolute; /* Modal width does not depend on header width */
    width: 100%;
    padding-right: 60px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.headerContent_withCloseIcon__BhYxF {
}

.controls__vktFm {
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    align-items: center;
}

.control__eANs8 + .control__eANs8 {
        margin-left: 15px;
    }

.closeButton__Raly2 {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0NkIzRTc3MkY4NjRFNjExQTJCN0E5OUY4MzE3OEUxRCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpGOUQ2QTAzODc2M0MxMUU2QkE1OEVGQjE5NEFGODIwRSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpGOUQ2QTAzNzc2M0MxMUU2QkE1OEVGQjE5NEFGODIwRSIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IFdpbmRvd3MiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyMzg3QjQ4RTI3NjhFNjExOUU4ODkyRkUyMzlBODE2QyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkIzRTc3MkY4NjRFNjExQTJCN0E5OUY4MzE3OEUxRCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PoIhXKEAAABgSURBVHjaYmBgYHjBQBl4gcEg1YD///9jN5EUF6AbQopBcHXYDCHGIBR5XIbgMwhDHJ8h2DRgNZiQIcgacXqRGENA4C8+Sbq4hOIwoTh2KE4nFKdYivMOxbmY4vIEIMAAwtZK0SI573cAAAAASUVORK5CYII=');
    width: 17px;
    height: 17px;
    opacity: .5;
    transition: opacity .3s;
}

.closeButton__Raly2:hover {
        cursor: pointer;
        opacity: 1;
    }

.content__sh7ES {
    padding: 20px;
}

.content_noHeader__VCW8r {
}

.footer__c-DDX {
    border-top: 1px solid #E5E5E5;
    padding: 20px;
}



.heading__Uv3u6 {
    margin: 0;
    padding: 0;
}

.heading_level_1__-eh1Y {

}

.heading_level_2__-x-y- {

}

.heading_level_3__9hvX8 {

}

.heading_level_4__J26iI {

}

.heading_level_5__1pPWv {

}

.heading_level_6__m6Xr4 {

}

.heading_level_7__s-fRU {

}



.textarea__0oBgR {
    font: 14px sans-serif;

    width: 100%;
    box-sizing: border-box;
    overflow: auto; /* Note: to prevent a permanent scroll in the IE */

    border: 1px solid #CCCCCC;
    border-radius: 4px;

    padding: 6px 12px;

    background-color: #FFFFFF;
}

    .textarea__0oBgR:active,
    .textarea__0oBgR:focus {
        outline: none;
    }

.textarea_error__SzfQe {
    border-color: #FF0000;
}

.textarea_disabled__1mKJe {
}

.textarea_withCounter__Y9yqq {
    display: block;
}

.textareaWrapper__IYZMJ {
    position: relative;
}

.textareaWrapper__counter__UJf9Z {
    font: 12px sans-serif;
    position: absolute;
    right: 12px;
    bottom: 1px;
    background-color: rgba(255, 255, 255, 0.7);
    color: #777777;
    padding: 5px;
}

.textareaWrapper__counter_warning__vuZSv {
    color: #AD2424;
}

.scheduleControl__BqSTQ {

}

.topHeaders__NFuJS {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
}

.timeText__N5R7g {

}

.dayHeader__V5vZk {
    justify-content: center;
}

.hourControls__Hj-Fq {
    display: flex;
    justify-content: space-between;
}

.hourControls__Hj-Fq + .hourControls__Hj-Fq {
       margin-top: 5px;
    }

.hourHeader__npphw {
    padding-left: 10px;
}

.hourControl__bG-9G {
    position: relative; /* Needed for hourControl__hoverIcon position absolute */
    border: 1px solid;
}

.hourControl_workday__D0I9g {

}

.hourControl_holiday__l9Nfm {

}

.hourControl_inactive__VILGy:hover .hourControl__hoverIcon__rMvL6 {
            display: block;
        }

.hourControl_active__cW2Im {
    border: none;
}

.hourControl__hoverIcon__rMvL6 {
    display: none;
    pointer-events: none; /* Needed to allow a click on the underlying .hourControl */

    position: absolute;
    top: 0;
    left: 0;
}

.dayHeader__V5vZk + .dayHeader__V5vZk, .hourControl__bG-9G + .hourControl__bG-9G {
        margin-left: 2px;
    }

.dayHeader__V5vZk,
.timeText__N5R7g,
.hourHeader__npphw {
    display: flex;
    align-items: center;
}

.hourControl__bG-9G,
.timeText__N5R7g,
.dayHeader__V5vZk,
.hourHeader__npphw {
    box-sizing: border-box;
    height: 24px;
}

.hourHeader__npphw,
.timeText__N5R7g {
    width: 28px;
}

.dayHeader__V5vZk,
.hourControl__bG-9G {
    width: 24px;
}

.pointer__lMmht {
    cursor: pointer;
}

.radioButton__53Lpm + .radioButton__53Lpm {
    margin-left: 30px;
  }
  .radioButton__53Lpm {

  position: relative;
  display: inline-block;
  padding: 5px 0 5px 36px;
}
  .radioButton__53Lpm label {
    display: inline-block;
    font-size: 14px sans-serif;
    text-align: left;
    cursor: pointer;
  }
  .radioButton__53Lpm i {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #D5D5D5;
    background-color: #FFFFFF;
  }
  .radioButton__53Lpm i::before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      background-color: #2E86BB;
      border-radius: 50%;
      margin: 7px;
      transform: scale(0);
      transition: transform .1s;
    }
  .radioButton__53Lpm input[type=radio] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    left: 0;
    top: 0;
  }
  .radioButton__53Lpm input[type=radio]:checked + i::before {
      transform: scale(1);
    }

.radioButton_disabled__26DbV {

}

.radioButton__text__VEjjw {

}

.multiSelect__fgAx- {
    position: relative;
    font: 14px sans-serif;
}

.multiSelect_opened__6rurt {
}

.multiSelect_error__H2Y1G {
}

.multiSelect_disabled__1XHDM {
}

.multiSelect__control__E0cRG {
    position: relative;
    display: flex;
    width: 100%;
    height: 34px;
    box-sizing: border-box;

    border: 1px solid #CCCCCC;
    border-radius: 4px;

    padding: 0 12px;

    background-color: #FFFFFF;

    align-items: center;
}

.multiSelect__control_opened__KF1HQ {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.multiSelect__control_focused__9Wgtg {
}

.multiSelect__control_error__r9NIe {
    border-color: #FF0000;
}

.multiSelect__control_disabled__Gtlyp {
    transition: color .3s ease .5s, border-color .3s ease .5s, background-color .3s ease .5s;
}

.multiSelect__inputs__37GbJ {
    flex: 1 1 auto;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.multiSelect__values__--3cC {
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    padding: 0 12px 0 0;
}

.multiSelect__placeholder__yy3-R {
    color: #AAAAAA;
}

.multiSelect__input__Q9NVw {
    background: none transparent;
    border: 0 none;
    cursor: default;
    margin: 0;
    outline: none;
}

.multiSelect__input_hidden__HtEwl {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    height: 0;
    overflow: scroll;
    whiteSpace: pre;
}

.multiSelect__visibleInput__MhI0V {
}

.multiSelect__focusInput__Jo537 {
    width: 0;
    height: 0;
    border: none;
    cursor: none;
    outline: none;
    margin: 0;
    padding: 0;
}

.multiSelect__arrow__JkZsl {
    cursor: pointer;
    width: 10px;
    margin-top: 5px;
}

.multiSelect__arrow_disabled__xfsBW {
    cursor: default;
}

.multiSelect__arrowImage__cFFNp {
    border-color: #999999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
}

.multiSelect__arrowImage_opened__uuSyC {
    border-color: transparent transparent #999999;
    border-width: 0 5px 5px;
    border-top-color: #666666;
    position: relative;
    top: -2px;
}

.multiSelect__optionListWrapper__1nNvi {
    border: solid 1px #CCCCCC;
    border-radius: 0 0 4px 4px;
    border-top: none;
    position: absolute;
    top: 100%;
    z-index: 1000;
    background-color: #FFFFFF;
    width: 100%;
    box-sizing: border-box;
    max-height: 307px;
    overflow: hidden;
}

.multiSelect__optionList__FYxho {
    max-height: 306px;
    overflow-y: auto;
}

.multiSelect__option__SZjrr {
    height: 34px;
    padding: 0px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.multiSelect__option_selected__GZXjV {
    background-color: #DBDBDB;
}

.multiSelect__option_selectAll__up2dT,
.multiSelect__option_selectNone__Lk52I {
    font-weight: bold;
}

.multiSelect__option_focused__KsFvI {
    background-color: #DBDBDB;
}

.multiSelect__optionCheckboxWrapper__mHrip + .multiSelect__optionLabel__a-Ifd {
    padding-left: 12px;
}

.multiSelect__optionCheckboxWrapper__mHrip input {
        margin: 0px;
    }

.multiSelect__optionLabel__a-Ifd {
}

.multiSelect__optionCheckbox__JzcCe {
}

.multiSelect__optionCheckbox_selected__lzW-g {
}

.multiSelect__optionCheckbox_focused__eBQQE {

}

 .mobileNumber__Fk3fl {
    display: flex;
}

.mobileNumber__input__L7vH7 {
    flex: 1 1 auto;
    width: 0;
}

.mobileNumber__input__L7vH7:first-child {
        max-width: 100px;
        text-align: right;
    }

.mobileNumber__input__L7vH7 + .mobileNumber__input__L7vH7 {
        margin-left: 15px;
    }

.container__-gZmi {
    display: inline-table;
    border-spacing: 0;
    width: 100%;
}

.container__input__OeIVK {
    font: 14px sans-serif;

    display: table-cell;
    width: 100%;
    height: 34px;
    box-sizing: border-box;

    border: 1px solid #CCCCCC;
    border-radius: 4px;

    padding: 6px 12px;

    background-color: #FFFFFF;
}

.container__input__OeIVK:active,
    .container__input__OeIVK:focus {
        outline: none;
    }

.container__input__OeIVK:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
    }

.container__input__OeIVK:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }

.container__input_error__6xan8 {
    border-color: #FF0000;
}

.container__addon__v-UbC {
    font: 14px sans-serif;

    display: table-cell;
    min-width: 34px;
    width: 34px;
    height: 34px;
    box-sizing: border-box;

    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    color: #555555;
    background: #EEEEEE;

    border: 1px solid #CCC;
}

.container__addon__v-UbC:first-child {
        padding: 0 5px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

.container__addon__v-UbC:last-child {
        padding: 0 5px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

.container__addon_error__qTQvB {
    border-color: #FF0000;
}

.container__input_active__5eYfV {

}

.container__addon_active__3Sq2E {

}

.container__input_disabled__1-uUv {

}

.container__addon_disabled__ATlMk {

}

.container__fGVWF {
    position: relative;
}

.container__input__OGXdI {
    font: 14px sans-serif;

    width: 100%;
    height: 34px;
    box-sizing: border-box;

    border: 1px solid #CCCCCC;
    border-radius: 4px;

    padding: 6px 12px;

    background-color: #FFFFFF;
}

.container__input__OGXdI:active,
    .container__input__OGXdI:focus {
        outline: none;
    }

.container__input_error__neMju {
    border-color: #FF0000;
}

.container__input_disabled__IkUOA {

}

.container__strengthMeter__7GUCL {
    position: absolute;
    right: 15px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.container__hint__UWF-b {
    font: 12px sans-serif;

    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
    margin-bottom: 7px;
    padding: 4px 9px;
    border-radius: 5px;
    border: 1px solid #FBC302;
    background: #FBC302;
    box-shadow: 2px 3px 6px #CCCCCC;
    box-sizing: border-box;
    color: #FFFFFF;

    visibility: hidden;
    transform: translateY(10px);
    opacity: 0;
    transition: visibility step-end, opacity ease, transform step-end;
    transition-duration: 200ms;
}

.container__hint__UWF-b::before {
        position: absolute;
        top: 100%;
        right: 25px;
        content: " ";
        pointer-events: none;
        border: solid rgba(0, 0, 0, 0);
        border-top-color: #FBC302;
        border-width: 8px;
    }

.container__hint_opened__4feRq {
    transform: translateY(0);
    visibility: visible;
    transition: visibility step-start, opacity ease, transform ease;
    transition-duration: 200ms;
    opacity: 1;
}

.inputFile__s6X7C {
}

.inputFile__input__C5MbX {
    display: none;
}

.inputFile__button__2q8j2 {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.inputFile__addon__yix2d {
    border: none;
}

.inputFile__addon__yix2d:first-child {
        padding: 0;
    }

.input__8nK-u {
    font: 14px sans-serif;

    width: 100%;
    height: 34px;
    box-sizing: border-box;

    border: 1px solid #CCCCCC;
    border-radius: 4px;

    padding: 0px 12px;

    background-color: #FFFFFF;
}

    .input__8nK-u.input_showAsFocused__Ece8n,
    .input__8nK-u:active,
    .input__8nK-u:focus {
        outline: none;
    }

.input_error__XbUNb {
    border-color: #FF0000;
}

.input_disabled__2hMuW {
    transition: .3s ease .5s;
}

.ibanTemplate__e0njQ {
    margin-top: calc(-1 * 8px);
}

.ibanTemplate__input__uw-dy {
    margin-top: 8px;
    margin-right: 10px;
}

.simpleInput__Wkjj6 {
    width: 100%;
    margin-right: 10px;
}

.changeModeLink__u4bEO {
}

.formFooter__BUPJa {
    padding-top: 20px;
}
.formFooter_modal__9sLZ7 {
    border-top: 1px solid #E5E5E5;
    box-sizing: border-box;
    padding: 20px 20px 0;
    margin: 20px -20px 0;
}

.formError__fqFvJ {
    color: #FF0000;
}

.form__-8suX {

}
.field__bHyDv {
    display: flex;
    width: 100%;
}

    .field__bHyDv + .field__bHyDv {
        margin-top: 5px;
    }
.field_required__f29Ew {

}
.field_column__YHj2g {
    flex-direction: column;
}

.field_error__VoleP {
    color: #FF0000;
}
.field_disabled__9-wJT {

}

.label__3u6FP {
    height: 30px;
    box-sizing: border-box;

    display: flex;
    white-space: nowrap;
    align-items: center;
    text-align: right;

    margin-left: auto;
    padding-right: 10px;
}

.label__3u6FP:empty {
        display: none;
    }
.label_required__KD1AJ {

}

.label_column__2XB7C {
    text-align: left;
    margin-left: 0;
}
.label__labelElement__UOkLX {

}
.label__labelElement_forElement__SaC4a {
    cursor: pointer;
}
.label__labelElement_disabled__qVotY {
    cursor: default;
}

.controls_labeled__J4ttu,
.controls__EoWq7 {
    width: 210px;
    flex: 0 0 auto;
}

.control__blVHk {

}

.helpBlock__cwyUD {
    font: 14px sans-serif;
    width: 100%;
    color: gray;
    margin-top: 5px;
}

.errorsBlock__teEwk {
    width: 100%;
    margin-top: 5px;
}

.error__B-6Me {
    margin: 0;
    text-align: left;
}

.error__B-6Me + .error__B-6Me {
        margin-top: 5px;
    }

.dayMonthYear__4K4AN {
    display: flex;
}

.dayMonthYear__day__g6C0v + .dayMonthYear__month__WiMPf,
.dayMonthYear__month__WiMPf + .dayMonthYear__year__y7olv {
    margin-left: 15px;
}

.dayMonthYear__day__g6C0v,
.dayMonthYear__month__WiMPf,
.dayMonthYear__year__y7olv {
    flex: 1 0 0;
}

.rw-date-picker.rw-state-focus .rw-widget-picker {
                box-shadow: none;
            }
        .rw-date-picker.rw-state-disabled {
            cursor: default;
        }
        .rw-date-picker .rw-widget-picker {
            min-height: 34px;
        }
        .rw-date-picker .rw-widget-picker .rw-input {
                box-shadow: none;
            }
        .rw-date-picker .rw-popup {
            box-shadow: none;
        }
        .rw-date-picker .rw-calendar .rw-calendar-header .rw-calendar-btn {
                    border: 1px solid transparent;
                }
        .rw-date-picker .rw-calendar .rw-calendar-header .rw-calendar-btn:focus {
                        box-shadow: none;
                    }
        .rw-date-picker .rw-calendar .rw-calendar-grid .rw-cell:focus {
                        box-shadow: none;
                    }
        .rw-date-picker .rw-widget-input {
            box-shadow: none;
        }

.dateRangeField__wdfN0 {
    width: 430px;
    display: flex;
}

.dateRangeField__cell__-ODZD {
    flex: 1 0 0;
    min-width: 0; /* Note: to make it shrink below width: auto */
    height: 34px;
}

/* Note: needed for the IE to prevent a bug: IE makes its height = 0px when using flex basis 0 */

.dateRangeField__cell__-ODZD + .dateRangeField__cell__-ODZD {
        margin-left: 20px;
    }

/* We have set of classes */

.checkbox__7-tRL input {
    vertical-align: middle;
  }

.checkbox_error__hW3j2 {

}
.checkbox_disabled__Hogzb {

}
.checkbox__customStyle__B6Ivv {
  display: none;
}


/* We can make override styles, for example:  */


/*
.checkbox {
  position: relative;

  .checkbox__customStyle {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    background: #DEE1E4;
    transition: background 0.3s ease;
    cursor: pointer;
  }

  input[type=checkbox] {
    visibility: hidden;
    width: 0;
    height: 0;

    &:checked + .checkbox__customStyle {
      border: 5px solid #DEE1E4;
      background: #575CE4;
      transition: background 0.3s ease;
    }
  }
}
*/

.container__KhDNS {
    position: relative;
}
.container_opened__lhqIW::before {
        display: block;
        content: "";
        background: #FFFFFF;
        position: absolute;
        height: 4px;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #CCCCCC;
        border-top: none;
    }
.container__spinner__uhDWd {
    position: absolute;
    right: 12px;
    top: 8px;
}



.meter__7AnC- {
    width: 67px;
    height: 22px;
}

.meter__bar__kXjM- {
    display: inline-block;
    height: 22px;
    width: 4px;
    border-radius: 2px;
    background-color: #E4E4E4;
    font-size: 0;
}

.meter__bar__kXjM- + .meter__bar__kXjM- {
        margin-left: 3px;
    }

.meter__bar_low__kjnfG {
    background-color: #FF0000;
}

.meter__bar_medium__8bfuG  {
    background-color: #FBC300;
}

.meter__bar_high__TRQaC {
    background-color: #3BB300;
}



.contextMenuLink__aBdpO {
    position: relative;
}

.contextMenuLink__link__Fvk9c {

}

.contextMenuLink__hint__d1SSB {
    margin-top: -10px;
    padding: 25px;
    border-radius: 5px;
    position: fixed;
    border: 1px solid #000000;
    background: #FFFFFF;
    box-sizing: border-box;
    z-index: 1;

    visibility: hidden;
    transform: translateY(10px);
    opacity: 0;
    transition: visibility step-end, opacity ease, transform step-end;
    transition-duration: 200ms;
}

.contextMenuLink__hintArrow__fuTZo {
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
}

.contextMenuLink__hintArrow__fuTZo::after,
    .contextMenuLink__hintArrow__fuTZo::before {
        top: 100%;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border: solid rgba(0, 0, 0, 0);
    }

.contextMenuLink__hintArrow__fuTZo::after {
        border-top-color: #FFFFFF;
        border-width: 7px;
    }

.contextMenuLink__hintArrow__fuTZo::before {
        border-top-color: #000000;
        border-width: 8px;
    }

.contextMenuLink__hint_opened__ImY00 {
    transform: translateY(0);
    visibility: visible;
    transition: visibility step-start, opacity ease, transform ease;
    transition-duration: 200ms;
    opacity: 1;
}

.contextMenuLink__hint_left__ROvK4 {
}

.contextMenuLink__hintArrow_left__x3Idk::after,
    .contextMenuLink__hintArrow_left__x3Idk::before {
        left: 20px;
    }

.contextMenuLink__hintArrow_left__x3Idk::after {
        margin-left: -7px;
    }

.contextMenuLink__hintArrow_left__x3Idk::before {
        margin-left: -8px;
    }

.contextMenuLink__hint_right__J1rEC {

}

.contextMenuLink__hintArrow_right__goChS::after,
    .contextMenuLink__hintArrow_right__goChS::before {
        right: 20px;
    }

.contextMenuLink__hintArrow_right__goChS::after {
        margin-right: -7px;
    }

.contextMenuLink__hintArrow_right__goChS::before {
        margin-right: -8px;
    }


