.buttonsContainer {
    display: flex;
    justify-content: flex-end;
}

.buttonsContainer__button {
    min-width: 170px;
    width: 170px;
    height: 32px;

    & + & {
        margin-left: 15px;
    }
}
