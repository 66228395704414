@value hintBgColor: #FFFFFF;
@value hintBorderColor: #000000;
@value transitionDuration: 200ms;

.contextMenuLink {
    position: relative;
}

.contextMenuLink__link {

}

.contextMenuLink__hint {
    margin-top: -10px;
    padding: 25px;
    border-radius: 5px;
    position: fixed;
    border: 1px solid hintBorderColor;
    background: hintBgColor;
    box-sizing: border-box;
    z-index: 1;

    visibility: hidden;
    transform: translateY(10px);
    opacity: 0;
    transition: visibility step-end, opacity ease, transform step-end;
    transition-duration: transitionDuration;
}

.contextMenuLink__hintArrow {
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;

    &::after,
    &::before {
        top: 100%;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border: solid rgba(0, 0, 0, 0);
    }

    &::after {
        border-top-color: hintBgColor;
        border-width: 7px;
    }

    &::before {
        border-top-color: hintBorderColor;
        border-width: 8px;
    }
}

.contextMenuLink__hint_opened {
    transform: translateY(0);
    visibility: visible;
    transition: visibility step-start, opacity ease, transform ease;
    transition-duration: transitionDuration;
    opacity: 1;
}

.contextMenuLink__hint_left {
}

.contextMenuLink__hintArrow_left {
    &::after,
    &::before {
        left: 20px;
    }

    &::after {
        margin-left: -7px;
    }

    &::before {
        margin-left: -8px;
    }
}

.contextMenuLink__hint_right {

}

.contextMenuLink__hintArrow_right {
    &::after,
    &::before {
        right: 20px;
    }

    &::after {
        margin-right: -7px;
    }

    &::before {
        margin-right: -8px;
    }
}
