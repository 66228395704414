.container {
    position: relative;
}
.container_opened {
    &::before {
        display: block;
        content: "";
        background: #FFFFFF;
        position: absolute;
        height: 4px;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #CCCCCC;
        border-top: none;
    }
}
.container__spinner {
    position: absolute;
    right: 12px;
    top: 8px;
}
