.multiSelect {
    position: relative;
    font: 14px sans-serif;
}

.multiSelect_opened {
}

.multiSelect_error {
}

.multiSelect_disabled {
}

.multiSelect__control {
    position: relative;
    display: flex;
    width: 100%;
    height: 34px;
    box-sizing: border-box;

    border: 1px solid #CCCCCC;
    border-radius: 4px;

    padding: 0 12px;

    background-color: #FFFFFF;

    align-items: center;
}

.multiSelect__control_opened {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.multiSelect__control_focused {
}

.multiSelect__control_error {
    border-color: #FF0000;
}

.multiSelect__control_disabled {
    transition: color .3s ease .5s, border-color .3s ease .5s, background-color .3s ease .5s;
}

.multiSelect__inputs {
    flex: 1 1 auto;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.multiSelect__values {
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    padding: 0 12px 0 0;
}

.multiSelect__placeholder {
    color: #AAAAAA;
}

.multiSelect__input {
    background: none transparent;
    border: 0 none;
    cursor: default;
    margin: 0;
    outline: none;
}

.multiSelect__input_hidden {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    height: 0;
    overflow: scroll;
    whiteSpace: pre;
}

.multiSelect__visibleInput {
}

.multiSelect__focusInput {
    width: 0;
    height: 0;
    border: none;
    cursor: none;
    outline: none;
    margin: 0;
    padding: 0;
}

.multiSelect__arrow {
    cursor: pointer;
    width: 10px;
    margin-top: 5px;
}

.multiSelect__arrow_disabled {
    cursor: default;
}

.multiSelect__arrowImage {
    border-color: #999999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
}

.multiSelect__arrowImage_opened {
    border-color: transparent transparent #999999;
    border-width: 0 5px 5px;
    border-top-color: #666666;
    position: relative;
    top: -2px;
}

.multiSelect__optionListWrapper {
    border: solid 1px #CCCCCC;
    border-radius: 0 0 4px 4px;
    border-top: none;
    position: absolute;
    top: 100%;
    z-index: 1000;
    background-color: #FFFFFF;
    width: 100%;
    box-sizing: border-box;
    max-height: 307px;
    overflow: hidden;
}

.multiSelect__optionList {
    max-height: 306px;
    overflow-y: auto;
}

.multiSelect__option {
    height: 34px;
    padding: 0px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.multiSelect__option_selected {
    background-color: #DBDBDB;
}

.multiSelect__option_selectAll,
.multiSelect__option_selectNone {
    font-weight: bold;
}

.multiSelect__option_focused {
    background-color: #DBDBDB;
}

.multiSelect__optionCheckboxWrapper + .multiSelect__optionLabel {
    padding-left: 12px;
}

.multiSelect__optionCheckboxWrapper {
    input {
        margin: 0px;
    }
}

.multiSelect__optionLabel {
}

.multiSelect__optionCheckbox {
}

.multiSelect__optionCheckbox_selected {
}

.multiSelect__optionCheckbox_focused {

}
